* {
    transition: all 40ms;
    box-sizing: border-box;
}
html {
    margin: env(safe-area-inset);
    padding: env(safe-area-inset);
    scroll-behavior: smooth;
    font-family: "Inconsolata", sans-serif;
    font-weight: 400;
    letter-spacing: 0.3rem;
    font-size: 0.7rem;
    color: #101012;
    overflow-y: auto;
    white-space: normal;
    overflow-x: hidden;
}
body {
    background-image: url("./pages/landing/assets/paper.jpg");
    background-repeat: repeat-y;
    background-size: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
}
img {
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
:hover {
    transition: 0.2s ease-in-out;
}
