@import "~react-image-gallery/styles/css/image-gallery.css";

.myBullet {
    background-color: rgba(255,255,255,0.5) !important;
    border: none !important;
    box-shadow: none !important;
}
.myBullet:hover {
    background-color: rgba(255,255,255, 0.75) !important;
}
.myBullet:active {
    background-color: oldlace !important;
}
.active {
    background-color: oldlace !important;
}

.imgContainer > img {
    pointer-events: none !important;
    object-fit: cover !important;
    min-height: 500px;
    @media (max-width: 800px) {
        height: 60vh;
    }
}